<template>
  <div id="accountFlow">
    <div class="search-form ant-round-form">
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_009') }}: </span>
        <a-input
          v-model:value="searchQuery.applyNo"
          allow-clear
          @pressEnter="loadCurFlow(1)"
          @blur="loadCurFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_051') }}: </span>
        <a-input
          v-model:value="searchQuery.batchNo"
          allow-clear
          @pressEnter="loadCurFlow(1)"
          @blur="loadCurFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_013') }}: </span>
        <a-select
          v-model:value="searchQuery.currency"
          allow-clear
          show-search
          option-label-prop="label"
          option-filter-prop="label"
          @change="loadCurFlow(1)"
        >
          <a-select-option
            v-for="item in curList"
            :key="item.currency"
            :value="item.currency"
            :label="`${item.currency} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.currency)" class="select-img-country">
            <span>{{ item.currency }} - {{ item.currencyName }}</span>
          </a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_010') }}: </span>
        <a-select
          v-model:value="searchQuery.busiType"
          option-filter-prop="label"
          allow-clear
          show-search
          @change="loadCurFlow(1)"
        >
          <a-select-option
            v-for="flow in flowTypeList"
            :key="flow.code"
            :value="flow.code"
            :label="flow.name"
          >{{ flow.name }}</a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_011') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.dateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadCurFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_012') }}: </span>
        <a-select
          v-model:value="searchQuery.flowType"
          allow-clear
          @change="loadCurFlow(1)"
        >
          <a-select-option
            v-for="inOut in inOutTypeList"
            :key="inOut.code"
            :value="inOut.code"
          >{{ inOut.name }}</a-select-option>
        </a-select>
      </div>
    </div>
    <div class="table-part">
      <a-table
        :scroll="{ x: 1200 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="flowList"
      />
      <table-page
        :total="total"
        :page-opts="searchQuery"
        @update-page="updatePage"
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import { _account } from '@/api'
import { message } from 'ant-design-vue'
import i18n from '@/locale'
import TablePage from '@/components/table-page'
import { getCountryLogo, parseMoney, getAttrStatusList, getTradeStatusName } from '@/util'
import moment from 'moment'
export default {
  name: 'Flow',
  components: {
    'table-page': TablePage
  },
  setup() {
    const state = reactive({
      loading: true,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        applyNo: '',
        batchNo: '',
        currency: '',
        flowType: '',
        busiType: '',
        dateOpts: []
      },
      total: 0,
      curList: [],
      flowList: [],
      columns: [
        { key: 'applyNo', dataIndex: 'applyNo', title: i18n.global.t('pages_account_009'), width: 220 },
        { key: 'batchNo', dataIndex: 'batchNo', title: i18n.global.t('pages_account_051'), width: 220 },
        { key: 'currency', dataIndex: 'currency', title: i18n.global.t('pages_account_013'), width: 120 },
        { key: 'busiTypeText', dataIndex: 'busiTypeText', title: i18n.global.t('pages_account_014'), width: 200 },
        { key: 'busiDateText', dataIndex: 'busiDateText', title: i18n.global.t('pages_account_011'), width: 250 },
        { key: 'inAmountText', dataIndex: 'inAmountText', title: i18n.global.t('pages_account_015'), width: 130 },
        { key: 'outAmountText', dataIndex: 'outAmountText', title: i18n.global.t('pages_account_016'), width: 130 },
        { key: 'totalAmountText', dataIndex: 'totalAmountText', title: i18n.global.t('pages_account_017'), width: 130 }
      ]
    })

    state.flowTypeList = computed(() => {
      return getAttrStatusList('inOutType')
    })

    state.inOutTypeList = computed(() => {
      return getAttrStatusList('flowType')
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadCurFlow()
    }

    const downReceipts = async () => {
      const { dateOpts } = state.searchQuery

      if (!dateOpts[0] || !dateOpts[1]) {
        message.error(i18n.global.t('pages_account_082'))
      } else if (moment(dateOpts[1]).diff(dateOpts[0], 'months') > 2) {
        message.error(i18n.global.t('pages_account_083'))
      } else {
        const query = Object.assign({}, state.searchQuery, {
          beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
          endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
        })
        await _account.downloadAccountFlowPdf(query)
      }
    }

    const exportAccountFlow = async () => {
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _account.exportAccountFlow(query)
    }

    const loadRechargeSup = async () => {
      const res = await _account.getRechargeSup()
      if (res.data.succ) {
        state.curList = res.data.data.currencyList
      }
    }
    const loadCurFlow = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _account.getCurFlow(query)
      if (res.data.succ) {
        const { data } = res.data
        state.flowList = data.list.map(item => {
          return Object.assign({}, item, {
            busiTypeText: getTradeStatusName(item.busiType, 'inOutType'),
            inAmountText: parseMoney(item.inAmount, item.currency),
            outAmountText: parseMoney(item.outAmount, item.currency),
            totalAmountText: parseMoney(item.totalAmount, item.currency),
            busiDateText: item.busiDate
          })
        })
        state.total = data.total
      }
      state.loading = false
    }

    const initPage = () => {
      loadRechargeSup()
      loadCurFlow()
    }

    initPage()

    return {
      getCountryLogo,
      updatePage,
      downReceipts,
      exportAccountFlow,
      loadCurFlow,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#accountFlow {
  .search-form {
    margin-top: 8px;
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>

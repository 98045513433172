<template>
  <div id="accountDetails">
    <header-nav :content="`${$t('pages_router_014')} / ${ $t('pages_router_015')}`" />
    <div class="common-card-body">
      <a-tabs
        v-model:activeKey="labName"
        class="header-tabs"
      >
        <template v-if="pageLabs.length !== 0" #rightExtra>
          <a-button
            v-if="themeData.electronicReceiptBatch && ['payment', 'withdraw'].includes(labName)"
            type="primary"
            :loading="downLoading"
            @click="downReceipts(labName)"
          >
            <template #icon><download-outlined /></template>
            {{ downLoading ? $t('common_text_023') : $t('pages_account_079') }}
          </a-button>
          <a-button
            v-if="themeData.reconReceipt && labName === 'flow'"
            type="primary"
            :loading="downLoading"
            @click="downReconReceipts"
          >
            <template #icon><download-outlined /></template>
            {{ downLoading ? $t('common_text_023') : $t('pages_account_081') }}
          </a-button>
          <a-button
            type="primary"
            ghost
            style="margin-left: 24px"
            :loading="exportLoading"
            @click="exportFlow(labName)"
          >
            <template #icon><download-outlined /></template>
            {{ exportLoading ? $t('common_text_023') : (
              labName === 'flow' ? $t('pages_account_018') :
              labName === 'payment' ? $t('pages_account_059') :
              labName === 'recharge' ? $t('pages_account_019') :
              $t('pages_account_020')
            ) }}
          </a-button>
        </template>
        <a-tab-pane
          v-if="getUsedCheck(pageLabs, 160103)"
          key="flow"
          :tab="$t('pages_account_001')"
        >
          <flow ref="flowRef" />
        </a-tab-pane>
        <a-tab-pane
          v-if="getUsedCheck(pageLabs, 160105)"
          key="payment"
          :tab="$t('pages_account_052')"
        >
          <payment ref="paymentRef" :theme-data="themeData" />
        </a-tab-pane>
        <a-tab-pane
          v-if="getUsedCheck(pageLabs, 160101)"
          key="recharge"
          :tab="$t('pages_account_002')"
        >
          <recharge ref="rechargeRef" />
        </a-tab-pane>
        <a-tab-pane
          v-if="getUsedCheck(pageLabs, 160106)"
          key="withdraw"
          :tab="$t('pages_account_003')"
        >
          <withdraw ref="withDrawRef" :theme-data="themeData" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import { ref, reactive, toRefs, inject } from 'vue'
import { useRoute } from 'vue-router'
import HeaderNav from '@/components/header-nav'
import Flow from './components/flow'
import Payment from './components/payment'
import Recharge from './components/recharge'
import Withdraw from './components/withdraw'
import { _account } from '@/api'
import { getUsedCheck } from '@/util'
export default {
  name: 'AccountDetails',
  components: {
    'header-nav': HeaderNav,
    'flow': Flow,
    'payment': Payment,
    'recharge': Recharge,
    'withdraw': Withdraw
  },
  setup() {
    const route = useRoute()

    const flowRef = ref(null)
    const paymentRef = ref(null)
    const rechargeRef = ref(null)
    const withDrawRef = ref(null)

    const state = reactive({
      themeData: inject('$themeData'),
      downLoading: false,
      exportLoading: false,
      labName: route.query.labName || 'flow',
      pageLabs: []
    })

    const exportFlow = async (labName) => {
      state.exportLoading = true
      if (labName === 'flow') await flowRef.value.exportAccountFlow()
      else if (labName === 'payment') await paymentRef.value.exportPayFlow()
      else if (labName === 'recharge') await rechargeRef.value.exportRecharge()
      else if (labName === 'withdraw') await withDrawRef.value.exportWithdraw()
      state.exportLoading = false
    }

    const downReceipts = async (labName) => {
      state.downLoading = true
      if (labName === 'payment') await paymentRef.value.downPayReceipts()
      if (labName === 'withdraw') await withDrawRef.value.downWithdrawReceipts()
      state.downLoading = false
    }

    const downReconReceipts = async () => {
      state.downLoading = true
      await flowRef.value.downReceipts()
      state.downLoading = false
    }

    const loadChildrenMenu = async () => {
      const res = await _account.getChildrenMenu({ parentId: 1601 })

      if (res.data.succ) {
        state.pageLabs = res.data.data || []
      }
    }

    loadChildrenMenu()

    return {
      flowRef,
      paymentRef,
      rechargeRef,
      withDrawRef,
      getUsedCheck,
      exportFlow,
      downReceipts,
      downReconReceipts,
      ...toRefs(state)
    }
  }
}
</script>
<style lang="less" scoped>
#accountDetails {
  text-align: left;
  .common-card-body {
    padding: 8px 0;
    .header-tabs:deep(.ant-tabs-nav) {
      padding: 0 18px;
    }
  }
}
</style>

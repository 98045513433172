<template>
  <div id="accountWithDraw">
    <div class="search-form ant-round-form">
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_024') }}: </span>
        <a-select
          v-model:value="searchQuery.currency"
          allow-clear
          show-search
          option-label-prop="label"
          option-filter-prop="label"
          @change="loadWithdrawFlow(1)"
        >
          <a-select-option
            v-for="item in curList"
            :key="item.currency"
            :value="item.currency"
            :label="`${item.currency} - ${item.currencyName}`"
          >
            <img :src="getCountryLogo(item.currency)" class="select-img-country">
            <span>{{ item.currency }} - {{ item.currencyName }}</span>
          </a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_025') }}: </span>
        <a-input
          v-model:value="searchQuery.bankName"
          allow-clear
          @pressEnter="loadWithdrawFlow(1)"
          @blur="loadWithdrawFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_021') }}: </span>
        <a-select
          v-model:value="searchQuery.status"
          option-filter-prop="label"
          show-search
          allow-clear
          @change="loadWithdrawFlow(1)"
        >
          <a-select-option
            v-for="status in statusList"
            :key="status.code"
            :value="status.code"
            :label="status.name"
          >{{ status.name }}</a-select-option>
        </a-select>
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_026') }}: </span>
        <a-range-picker
          v-model:value="searchQuery.dateOpts"
          format="YYYY/MM/DD"
          value-format="YYYY-MM-DD"
          @change="loadWithdrawFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_028') }}: </span>
        <a-input
          v-model:value="searchQuery.batchNo"
          allow-clear
          @pressEnter="loadWithdrawFlow(1)"
          @blur="loadWithdrawFlow(1)"
        />
      </div>
      <div class="part flex-align">
        <span class="part-label">{{ $t('pages_account_027') }}: </span>
        <a-row style="width: 100%">
          <a-col :span="10">
            <a-input-number
              v-model:value="searchQuery.minAmount"
              style="width: 100%"
              :controls="false"
              @pressEnter="loadWithdrawFlow(1)"
              @blur="loadWithdrawFlow(1)"
            />
          </a-col>
          <a-col :span="4" style="text-align: center">-</a-col>
          <a-col :span="10">
            <a-input-number
              v-model:value="searchQuery.maxAmount"
              style="width: 100%"
              :controls="false"
              @pressEnter="loadWithdrawFlow(1)"
              @blur="loadWithdrawFlow(1)"
            />
          </a-col>
        </a-row>
      </div>
    </div>
    <div class="table-part">
      <a-table
        :scroll="{ x: 1500 }"
        :loading="loading"
        :pagination="false"
        :custom-header-row="() => { return {class: 'my-table-tr'}}"
        :columns="columns"
        :data-source="withdrawList"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'edit'">
            <a-button
              v-if="themeData.electronicReceipt && record.status === '02'"
              size="small"
              type="link"
              shape="round"
              class="btn-primary-empty-0"
              @click="downReceipt(record)"
            >{{ $t('pages_account_023') }}</a-button>
          </template>
        </template>
      </a-table>
      <table-page
        :total="total"
        :page-opts="searchQuery"
        @update-page="updatePage"
      />
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, computed } from 'vue'
import { _account } from '@/api'
import i18n from '@/locale'
import TablePage from '@/components/table-page'
import { message } from 'ant-design-vue'
import { getCountryLogo, parseMoney, getAttrStatusList, getTradeStatusName } from '@/util'
export default {
  name: 'Flow',
  components: {
    'table-page': TablePage
  },
  props: {
    themeData: {
      type: Object,
      default: () => { return {} }
    }
  },
  setup() {
    const state = reactive({
      loading: true,
      searchQuery: {
        pageNum: 1,
        pageSize: 10,
        currency: '',
        bankName: '',
        status: '',
        batchNo: '',
        minAmount: null,
        maxAmount: null,
        dateOpts: []
      },
      total: 0,
      curList: [],
      withdrawList: [],
      columns: [
        { key: 'batchNo', dataIndex: 'batchNo', title: i18n.global.t('pages_account_028'), width: 200 },
        { key: 'currency', dataIndex: 'currency', title: i18n.global.t('pages_account_024'), width: 120 },
        { key: 'amount', dataIndex: 'amountText', title: i18n.global.t('pages_account_027'), width: 150 },
        { key: 'accountName', dataIndex: 'accountName', title: i18n.global.t('pages_account_029'), width: 200, ellipsis: true },
        { key: 'accountNo', dataIndex: 'accountNo', title: i18n.global.t('pages_account_030'), width: 200 },
        { key: 'bankName', dataIndex: 'bankName', title: i18n.global.t('pages_account_025'), width: 200, ellipsis: true },
        { key: 'status', dataIndex: 'statusText', title: i18n.global.t('pages_account_021'), width: 150 },
        { key: 'createTime', dataIndex: 'createTime', title: i18n.global.t('pages_account_011'), width: 180 },
        { key: 'edit', title: i18n.global.t('common_text_001'), fixed: 'right', width: localStorage.getItem('locale-language') === 'en' ? 250 : 150, align: 'center' }
      ]
    })

    state.statusList = computed(() => {
      return getAttrStatusList('W')
    })

    const updatePage = (page, pageSize) => {
      state.searchQuery.pageNum = page
      state.searchQuery.pageSize = pageSize
      loadWithdrawFlow()
    }

    const exportWithdraw = async () => {
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _account.exportWithdraw(query)
    }

    const downReceipt = async (record) => {
      message.info(i18n.global.t('common_text_023'))
      await _account.downWithdrawReceipt({ batchNo: record.batchNo })
    }

    const downWithdrawReceipts = async () => {
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      await _account.downWithdrawReceipts(query)
    }

    const loadRechargeSup = async () => {
      const res = await _account.getRechargeSup()
      if (res.data.succ) {
        state.curList = res.data.data.currencyList
      }
    }
    const loadWithdrawFlow = async (pageNum) => {
      state.loading = true
      if (!state.searchQuery.dateOpts) state.searchQuery.dateOpts = []
      if (pageNum) state.searchQuery.pageNum = pageNum
      const { dateOpts } = state.searchQuery
      const query = Object.assign({}, state.searchQuery, {
        beginDate: dateOpts[0] ? `${dateOpts[0]} 00:00:00` : '',
        endDate: dateOpts[1] ? `${dateOpts[1]} 23:59:59` : ''
      })
      const res = await _account.getWithdrawRecord(query)
      if (res.data.succ) {
        const { data } = res.data
        state.withdrawList = data.list.map(item => {
          return Object.assign({}, item, {
            statusText: getTradeStatusName(item.status, 'W'),
            updateTime: item.updateTime || '-',
            tradeAmountText: parseMoney(item.tradeAmount, item.currency),
            amountText: parseMoney(item.amount, item.currency)
          })
        })
        state.total = data.total
      }
      state.loading = false
    }

    const initPage = () => {
      loadRechargeSup()
      loadWithdrawFlow()
    }

    initPage()

    return {
      getCountryLogo,
      downReceipt,
      updatePage,
      exportWithdraw,
      downWithdrawReceipts,
      loadWithdrawFlow,
      ...toRefs(state)
    }
  }
}
</script>

<style lang="less" scoped>
#accountWithDraw {
  .search-form {
    margin-top: 8px;
  }
  .table-part {
    margin-top: 12px;
  }
}
</style>
